import { createApp } from "vue";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import App from "./App.vue";
import router from "./router";
import { isTouchDevice } from "./utils/helpers";
import PrimeVue from "primevue/config";
import DialogService from "primevue/dialogservice";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Aura from "@primevue/themes/aura";
import "primeflex/primeflex.css";

// import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "quill/dist/quill.snow.css"; // Import Quill's Snow theme
import "./assets/main.scss"; // Your custom SCSS file

const app = createApp(App);
app.directive("tooltip", Tooltip); // Registering the Tooltip directive

app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);

app.use(PrimeVue, {
  // Default theme configuration
  theme: {
    preset: Aura,
    options: {
      prefix: "corp",
      //   darkModeSelector: "system",
      //   cssLayer: false,
    },
  },
});
if (isTouchDevice) {
  document.getElementsByTagName("body")[0].classList.add("touch");
}
if (
  Capacitor.getPlatform() === "ios" ||
  Capacitor.getPlatform() === "android"
) {
  document.getElementsByTagName("body")[0].classList.add("native");
  document.getElementsByTagName("body")[0].classList.add("touch");
  // Show the status bar (if it's hidden)
  StatusBar.show();
}
app.use(router);
app.mount("#app");
