// import { isMobile } from "./utils/helpers";
import { useSidebarState } from "./hooks/useSidebarState";
import { isMobile } from "./utils/helpers";

// const closeSidebar = () => {};

// const openSidebar = () => {};
const sidebar = useSidebarState();
export default (router) => {
  return [
    {
      before: (next) => {
        router.replace("/");
        next();
      },
      selector: ".p-toast",
      position: "topcenter",
      icon: "pi pi-bolt",
      heading: "Quick Start Tutorial",
      copy: "Follow this step-by-step guide to quickly master the essentials of using the application.",
    },
    {
      selector: isMobile ? "#note-new .new-actions" : "#note-new",
      position: isMobile ? "bottom" : "topcenter",
      heading: "Adding a new note",
      icon: "pi pi-plus",
      copy: isMobile
        ? "Effortlessly create a new note by clicking the '+' button or the image icon to get started. Use filters to quickly locate the content you need."
        : "Start creating a new note effortlessly by clicking on 'Take a note...' or selecting the task or image icon to dive right in.",
      style: isMobile ? { marginBottom: "100px" } : { marginLeft: "200px" },
    },
    {
      before: (next) => {
        document.querySelector("#note-new textarea")?.click();
        setTimeout(() => {
          document
            .querySelector("#note-new .expanded-wrap")
            ?.classList.add("tutorial-blur");
          document.querySelector("#note-new textarea")?.blur();
        }, 0);
        setTimeout(() => {
          next();
        }, 200);
      },
      selector: "#note-new footer",
      position: isMobile ? "left" : "topcenter",
      heading: "Note actions",
      copy: "Notes can be simple text or enhanced with multimedia, tasks, labels, reminders, and collaboration features, all easily accessible through the provided actions—offering endless ways to organize and share your ideas.",
      style: isMobile ? { marginRight: "80px" } : null,
      after: () => {
        document.querySelector("#note-new .close")?.click();
        document
          .querySelector("#note-new .expanded-wrap")
          ?.classList.remove("tutorial-blur");
      },
    },
    {
      // before: async (next) => {
      //   next();
      // },
      selector: ".header .icons .set",
      position: "topright",
      heading: "Header Actions",
      copy: "Quickly access notifications for reminders and shared content, your calendar, and the user menu for managing your account, contacts, password, and settings.",
      style: { marginTop: "100px" },
    },
    {
      before: async (next) => {
        if (!sidebar.isSidebarOpen.value) {
          // open it
          sidebar.toggleSidebar();
          setTimeout(() => {
            next();
          }, 200);
        } else {
          next();
        }
      },
      selector: ".sidebar",
      position: isMobile ? "right" : "topcenter",
      heading: "Main menu",
      copy: "Effortlessly navigate through various note categories and collections by tags to quickly find the content you need.",
      // style: { marginTop: "80px" },
    },
    isMobile
      ? null
      : {
          // before: async (next) => {
          //   next();
          // },
          selector: ".header .trigger",
          position: "topleft",
          heading: "Menu and Filters",
          copy: "Effortlessly toggle between the main navigation and filters to quickly locate your content.",
          style: { marginTop: "80px" },
        },
    isMobile
      ? null
      : {
          before: async (next) => {
            if (sidebar.isSidebarOpen.value) {
              // close it
              sidebar.toggleSidebar();
              next();
            }
          },
          selector: isMobile ? "#mobile-filters" : ".pagesidebar",
          position: isMobile ? "center" : "top",
          heading: "Filters",
          copy: "Quickly find your notes by searching based on content, creation date, creator, color, tags, and more—making it easy to locate exactly what you need.",
          style: { marginTop: "80px" },
        },
    {
      before: async (next) => {
        if (isMobile && sidebar.isSidebarOpen.value) {
          // close it
          sidebar.toggleSidebar();
          next();
        }
        document.querySelector("#sort-filter")?.click();
        next();
      },
      selector: ".sort",
      position: isMobile ? "bottom" : "bottom",
      heading: "Sort selector",
      copy: "Organize your content by relevance, helping you focus on what matters most.",
      style: isMobile ? {} : { marginTop: "40px" },
    },
    {
      selector: ".view-switcher",
      position: "right",
      heading: "View selector",
      copy: "Customize your view by toggling between grid and list layouts to suit your preference.",
      style: isMobile ? { marginTop: "-200px" } : null,
    },
    {
      selector: ".p-toast",
      position: "topcenter",
      logo: true,
      heading: "Ready to Begin?",
      copy: "Get started and discover how simple and powerful the application can be.",
    },
  ].filter((n) => n !== null);
};
