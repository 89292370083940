import { ref } from "vue";

const DEVICE_ID_KEY = "deviceId";

export function useDeviceId() {
  const deviceId = ref(null);

  // Function to generate a new unique deviceId
  const generateDeviceId = () => {
    // return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    //   /[xy]/g,
    //   function (c) {
    //     const r = (Math.random() * 16) | 0;
    //     const v = c === "x" ? r : (r & 0x3) | 0x8;
    //     return v.toString(16);
    //   }
    // );
    const userAgent = navigator.userAgent;
    const screenSize = `${screen.width}x${screen.height}`;
    const randomComponent = Math.random().toString(36).substring(2, 15);
    return btoa(`${userAgent}-${screenSize}-${randomComponent}`);
  };

  // Load deviceId from localStorage or generate a new one if not found
  const loadDeviceId = () => {
    const storedDeviceId = localStorage.getItem(DEVICE_ID_KEY);
    if (storedDeviceId) {
      deviceId.value = storedDeviceId;
    } else {
      const newDeviceId = generateDeviceId();
      localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
      deviceId.value = newDeviceId;
    }
  };

  // Optionally allow regenerating the deviceId
  const regenerateDeviceId = () => {
    const newDeviceId = generateDeviceId();
    localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
    deviceId.value = newDeviceId;
  };

  loadDeviceId(); // Load deviceId when the hook is initialized

  return { deviceId, regenerateDeviceId };
}
