import { computed } from "vue";
import store from "../store";
import api from "../utils/api";

const fetchInProgress = new Map();
export function useUserStore() {
  const users = computed(() => store.state.users);
  const userSuggestions = computed(() => store.state.userSuggestions);
  const sharedWithSuggestions = computed(
    () => store.state.sharedWithSuggestions
  );

  async function fetchUsers() {
    try {
      const response = await api.get("/api/users");
      store.mutations.addModel("users", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  async function fetchUserById(userId) {
    try {
      if (!userId) {
        return;
      }
      // Check if the user already exists in the store
      const existing = users.value.find((u) => u.id === userId);
      if (existing) {
        return existing;
      }

      // Check if a fetch is already in progress for this userId
      if (fetchInProgress.has(userId)) {
        return await fetchInProgress.get(userId);
      }

      // Create a fetch promise and store it in fetchInProgress
      const fetchPromise = api
        .get(`/api/users/${userId}`)
        .then((response) => {
          store.mutations.updateModel("users", userId, response.data);
          return response.data;
        })
        .finally(() => {
          // Remove the promise from fetchInProgress after completion
          fetchInProgress.delete(userId);
        });

      fetchInProgress.set(userId, fetchPromise);

      return await fetchPromise;
    } catch (error) {
      console.error("Error fetching user by id:", error);
      // Ensure the failed fetch is removed from fetchInProgress
      fetchInProgress.delete(userId);
      throw error;
    }
  }

  async function createUser(userData) {
    try {
      const response = await api.post("/api/users", userData);
      store.mutations.addModel("users", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }

  async function updateUser(userId, userData) {
    try {
      const response = await api.put(`/api/users/${userId}`, userData);
      store.mutations.updateModel("users", userId, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  }

  async function deleteUser(userId) {
    try {
      await api.delete(`/api/users/${userId}`);
      store.mutations.removeModel("users", userId);
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  }

  async function fetchUserSuggestions({
    query,
    page = 1,
    size = 50,
    autoLoad = false,
  }) {
    const state = {
      currentPage: page,
      totalPages: 0,
      totalItems: 0,
      suggestions: [],
      loading: false,
      error: null,
    };

    async function loadPage(pageToLoad) {
      console.log("Fetching page:", pageToLoad);
      state.loading = true;
      state.error = null;

      try {
        const response = await api.get(`/api/users/search`, {
          params: {
            q: query,
            limit: size,
            page: pageToLoad,
          },
        });

        const { currentPage, pages, total, users } = response.data;

        state.currentPage = currentPage;
        state.totalPages = pages;
        state.totalItems = total;
        state.suggestions =
          pageToLoad === 1 ? users : [...state.suggestions, ...users]; // Append new results
      } catch (error) {
        console.error("Error fetching user suggestions:", error);
        state.error =
          error.message || "An error occurred while fetching suggestions.";
      } finally {
        state.loading = false;
      }
    }

    async function autoLoadAllPages() {
      while (state.currentPage < state.totalPages) {
        await loadPage(state.currentPage + 1);
      }
    }

    // Initial load
    await loadPage(state.currentPage);

    // Optionally load all pages automatically
    if (autoLoad) {
      await autoLoadAllPages();
    }
    store.mutations.setState("userSuggestions", state.suggestions);
    return state.suggestions;
  }

  async function fetchSharedWithSuggestions({
    query,
    page = 1,
    size = 50,
    autoLoad = false,
  }) {
    const state = {
      currentPage: page,
      totalPages: 0,
      totalItems: 0,
      suggestions: [],
      loading: false,
      error: null,
    };

    async function loadPage(pageToLoad) {
      console.log("Fetching page:", pageToLoad);
      state.loading = true;
      state.error = null;

      try {
        const response = await api.get(`/api/users/shared-with`, {
          params: {
            q: query,
            limit: size,
            page: pageToLoad,
          },
        });

        const { currentPage, pages, total, users } = response.data;

        state.currentPage = currentPage;
        state.totalPages = pages;
        state.totalItems = total;
        state.suggestions =
          pageToLoad === 1 ? users : [...state.suggestions, ...users]; // Append new results
      } catch (error) {
        console.error("Error fetching user suggestions:", error);
        state.error =
          error.message || "An error occurred while fetching suggestions.";
      } finally {
        state.loading = false;
      }
    }

    async function autoLoadAllPages() {
      while (state.currentPage < state.totalPages) {
        await loadPage(state.currentPage + 1);
      }
    }

    // Initial load
    await loadPage(state.currentPage);

    // Optionally load all pages automatically
    if (autoLoad) {
      await autoLoadAllPages();
    }
    store.mutations.setState("sharedWithSuggestions", state.suggestions);
    return state.suggestions;
  }
  const getUserName = (userId) => {
    return (
      users.value?.find((u) => u.id === userId)?.username || "Unknown User"
    );
  };

  const getUserProfilePicture = (userId) => {
    return (
      users.value?.find((u) => u.id === userId)?.profilePicture ||
      `https://placehold.co/100x100/EEE/31343C?font=open-sans&text=${
        getUserName(userId)[0]
      }`
    );
  };

  return {
    users,
    userSuggestions,
    sharedWithSuggestions,
    fetchUsers,
    fetchUserById,
    createUser,
    updateUser,
    deleteUser,
    fetchUserSuggestions,
    fetchSharedWithSuggestions,
    getUserName,
    getUserProfilePicture,
  };
}
