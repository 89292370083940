<template>
  <Header />
  <ConfirmDialog>
    <template #message="slotProps">
      <div class="flex">
        <i
          :class="slotProps.message.icon"
          class="!text-6xl text-primary-500"
        ></i>
        <div v-html="slotProps.message.message" />
      </div> </template
  ></ConfirmDialog>
  <Toast>
    <template #message="slotProps">
      <div class="flex-1 toast-bck" v-if="slotProps.reminder">
        <div class="font-semibold text-sm my-1 flex px-2">
          Reminder
          <div class="flex-1" />
          <LiveTimeFromNow :time="slotProps.message.start" />
        </div>
        <button
          :style="slotProps.message.color"
          @click="slotProps.message.onClick"
          class="w-full text-left border-none outline-0 p-2 border-round-md"
        >
          <div class="font-medium text-lg my-1">
            {{ slotProps.message.summary }}
          </div>
          <div class="font-medium text-sm my-1">
            {{ slotProps.message.detail }}
          </div>
          <span class="font-bold text-primary">View Details</span>
        </button>
      </div>
      <div v-else class="flex-1 toast-bck p-1">
        <div class="font-medium text-lg my-1">
          {{ slotProps.message.summary }}
        </div>
        <div class="font-medium text-sm my-1">
          {{ slotProps.message.detail }}
        </div>
      </div>
    </template></Toast
  >
  <div class="content">
    <Sidebar v-if="isAuthenticated && mounted" />
    <Suspense>
      <template #default>
        <router-view v-if="!isAuthenticating && mounted" />
      </template>
      <template #fallback>
        <PageLoader />
      </template>
    </Suspense>

    <DynamicDialog />
  </div>

  <div class="tutorial-mask">
    <svg
      v-if="tutorial.isShowing"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      id="cutout-svg"
    >
      <path
        id="hole"
        fill="rgba(0, 0, 0, 0.86)"
        fill-rule="evenodd"
        d="M 0 0 H 100 V 100 H 0 Z M 0 0 H 0 V 0 H 0 Z"
      />
    </svg>
  </div>

  <template v-if="tutorial.isShowing && tutorial.isReady">
    <Dialog
      v-for="(tutorialStep, index) in steps"
      :key="tutorialStep.selector"
      class="tutorial-modal"
      :pt="{
        mask: { class: 'tutorial-dialog-mask' },
      }"
      :draggable="false"
      :closable="false"
      :visible="index == step && tutorial.isShowing && tutorial.isReady"
      :position="tutorialStep.position"
      :style="tutorialStep.style"
    >
      <div class="text-center" v-if="tutorialStep.icon || tutorialStep.logo">
        <i
          v-if="tutorialStep.icon"
          :class="`${tutorialStep.icon} tutorial-icon`"
        />
        <img
          v-if="tutorialStep.logo"
          src="/apple-icon-144x144.png"
          class="h-5rem block mx-auto"
        />
      </div>
      <h3 class="text-center" v-if="tutorialStep.heading">
        {{ tutorialStep.heading }}
      </h3>
      <p>{{ tutorialStep.copy }}</p>
      <div class="flex">
        <Button
          text
          severity="secondary"
          size="small"
          label="Exit tutorial"
          v-if="steps.length - 1 !== step"
          @click="
            () => {
              tutorial.exit();
            }
          "
        />
        <div class="flex-1" />
        <Button
          :label="steps.length - 1 === step ? 'Start Exploring' : 'Next'"
          @click="
            () => {
              tutorial.next();
            }
          "
        />
        <div class="flex-1" v-if="steps.length - 1 === step" />
      </div>
    </Dialog>
  </template>
</template>

<script setup>
import { watch, ref, defineAsyncComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Capacitor } from "@capacitor/core";

import Toast from "primevue/toast";
import DynamicDialog from "primevue/dynamicdialog";
import { useDialog } from "primevue/usedialog";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import ProgressSpinner from "primevue/progressspinner";

import { useAuthStore } from "./hooks/useAuthStore";
import { useDeviceLocation } from "./hooks/useDeviceLocation";
import { usePreferenceStore } from "./hooks/usePrefrenceStore";
import { useInvitationStore } from "./hooks/useInvitationStore";
import { useDialogService } from "./hooks/useDialogService";
import { useWebSocket } from "./hooks/useWebsocketConnection";
import { usePushNotifications } from "./hooks/usePushNotifications";
// import { useUserStore } from "./hooks/useUserStore";
import useTutorial from "./hooks/useTutorial";
import tutorialSteps from "./tutorial.config";
import PageLoader from "./components/PageLoader.vue";

const Header = defineAsyncComponent(() =>
  import("./components/HeaderComp.vue")
);

const Sidebar = defineAsyncComponent(() =>
  import("./components/SidebarComp.vue")
);
const LiveTimeFromNow = defineAsyncComponent({
  loader: () => import("./components/LiveTimeFromNow.vue"),
});
const WelcomeDialog = defineAsyncComponent({
  loader: () => import("./components/WelcomeDialog.vue"),
  delay: 0,
  loadingComponent: ProgressSpinner,
});
const ThemeDialog = defineAsyncComponent({
  loader: () => import("./components/ThemeDialog.vue"),
  delay: 0,
  loadingComponent: ProgressSpinner,
});

useWebSocket();
const mounted = ref(false);

const dialog = useDialog();

const { isAuthenticated, isAuthenticating } = useAuthStore();
const { preferences, getPreferences } = usePreferenceStore();
const { startLocationTracking, stopLocationTracking } = useDeviceLocation();
const { startInviteLoop, stopInviteLoop } = useInvitationStore();
// const { fetchUserContactSuggestions } = useUserStore();
const dialogService = useDialogService();

const showInitalTheme = async () => {
  const theme = preferences?.value?.theme;
  if (theme) {
    return;
  }
  return new Promise((resolve) => {
    const d = dialog.open(ThemeDialog, {
      props: {
        showHeader: false,
        draggable: false,
        blockScroll: true,
        modal: true,
      },
      emits: {
        onAccept: () => {
          d.close();
          resolve(true);
        },
        onClose: () => {
          d.close();
          resolve(true); // Resolve with `false` when declined
        },
      },
    });
  });
};
const showInitalWelcome = async () => {
  const welcome = preferences?.value?.welcome;
  if (welcome) {
    return;
  }
  return new Promise((resolve) => {
    const showTutorial = () => {
      tutorial.show();
      watch(tutorial.isShowing, () => {
        if (!tutorial.isShowing.value) {
          resolve(true);
        }
      });
    };
    const d = dialog.open(WelcomeDialog, {
      props: {
        showHeader: false,
        draggable: false,
        blockScroll: true,
        modal: true,
      },
      emits: {
        onAccept: () => {
          d.close();
          showTutorial();
        },
        onDecline: () => {
          d.close();
          resolve(false); // Resolve with `false` when declined
        },
      },
    });
  });
};

onMounted(() => {
  document.querySelector("#app-load").classList.add("hide");
  dialogService.set(dialog);
  mounted.value = true;
});
watch(
  [isAuthenticated, mounted],
  async () => {
    if (isAuthenticated.value && mounted.value) {
      await getPreferences();
      await showInitalWelcome();
      await showInitalTheme();
      // await fetchUserContactSuggestions({ autoLoad: true });
      // await fetchUserSuggestions({ autoLoad: true });

      startLocationTracking();
      startInviteLoop();
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        usePushNotifications();
      }
    } else {
      stopLocationTracking();
      stopInviteLoop();
    }
  },
  { immediate: true }
);
function updateHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// Update the height on page load and on window resize
window.addEventListener("resize", updateHeight);
window.addEventListener("load", updateHeight);

const router = useRouter();

const tutorial = useTutorial();
const step = ref(tutorial.step);
// const { step } = toRefs(tutorial);
const steps = tutorialSteps(router);
watch(
  tutorial,
  () => {
    step.value = tutorial.step;
  },
  { deep: true }
);
tutorial.init({ steps });
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
}
.content {
  position: relative;
  display: flex;
  flex-grow: 1;
}
.pi.tutorial-icon {
  font-size: 4rem;
  color: var(--primary-color);
}
</style>
