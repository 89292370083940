import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { defineAsyncComponent } from "vue";
import { useToast } from "primevue/usetoast";
import { useDialogService } from "../hooks/useDialogService";

const NotificationDialog = defineAsyncComponent(() =>
  import("../components/NotificationDialog.vue")
);

const isNative =
  Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android";

export function playDing() {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.type = "triangle"; // 'triangle' waveform for a softer sound
  oscillator.frequency.setValueAtTime(1100, audioContext.currentTime); // higher frequency for a "ding"

  // Gradually reduce the volume to create a fading "ding"
  gainNode.gain.setValueAtTime(1, audioContext.currentTime); // start at full volume
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioContext.currentTime + 0.5
  ); // fade out over 1 second

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.start();
  oscillator.stop(audioContext.currentTime + 1); // play for 1 second
}

export function useNotify() {
  const notify = async (title, message, data, toastOptions) => {
    // Check if the platform is native (iOS or Android)
    if (isNative) {
      try {
        // Request permission for notifications
        const hasPermission = await requestNotificationPermission();
        if (hasPermission) {
          // Show local notification for native devices
          await showLocalNotification(title, message, data);
        } else {
          console.log("Notification permission denied");
          // Fallback to Toast if permission is not granted
          showToast(message, toastOptions);
        }
      } catch (error) {
        console.error("Error showing native notification:", error);
        showToast(message, toastOptions); // Fallback to toast on error
      }
    } else {
      console.log("notify web");
      // For web: Check if Web Notifications are supported and use them
      const permission = await requestWebNotificationPermission();
      console.log("permission", permission);
      if (permission === "granted") {
        showWebNotification(title, message, data);
      } else {
        // Fallback to showing a toast notification if permission is denied or not supported
        showToast(message, toastOptions);
      }
    }
  };

  const showLocalNotification = async (title, message, data) => {
    await LocalNotifications.schedule({
      notifications: [
        {
          title: `Local: ${title}`,
          body: message,
          id: new Date().getTime(),
          schedule: { at: new Date(Date.now() + 1000) },
          sound: null,
          attachments: null,
          extra: data,
        },
      ],
    });
  };

  const showWebNotification = (title, message, data = {}) => {
    const notificationOptions = {
      body: message,
      //   icon: "/path/to/icon.png", // Replace with the path to your notification icon
      data: data,
    };

    // Show the web notification
    const notification = new Notification(title, notificationOptions);
    notification.onerror = (e) => {
      console.log("error", e);
    };
    notification.onshow = (e) => {
      console.log("onshow", e);
    };
    // Add click event to the notification (optional)
    notification.onclick = () => {
      console.log("Notification clicked");
      // You can also navigate the user to a specific route
      window.location.href = `/note/${data.noteId}`;
    };
    console.log("Notification shown:", notification);
  };

  const showToast = (message, toastOptions = {}) => {
    const { toast } = useToast();
    const defaultToast = {
      severity: "info",
      summary: "Notification",
      detail: message,
      life: 3000,
      reminder: true,
    };
    toast.add({ ...defaultToast, ...toastOptions });
  };

  const requestNotificationPermission = async () => {
    console.log("requestNotificationPermission", isNative);
    if (isNative) {
      const { display } = await LocalNotifications.requestPermissions();
      console.log("granted", display === "granted");
      return display === "granted";
    }
    return false; // Assume permission is not needed on web
  };

  async function checkWebNotificationPermission() {
    const showPrompt = async () => {
      const dialogService = useDialogService();
      const dialog = dialogService.get();
      if (!dialog) {
        return true;
      }
      return new Promise((resolve) => {
        const d = dialog.open(NotificationDialog, {
          props: {
            showHeader: false,
            draggable: false,
          },
          emits: {
            onAccept: () => {
              console.log("accept");
              d.close();
              resolve(true); // Resolve with `true` when accepted
            },
            onDecline: () => {
              console.log("decline");
              d.close();
              resolve(false); // Resolve with `false` when declined
            },
          },
        });
      });
    };
    if (isNative) {
      const { display } = await LocalNotifications.checkPermissions();
      if (display === "prompt") {
        await showPrompt();
      } else {
        return display === "granted";
      }
    } else {
      const result = await navigator.permissions.query({
        name: "notifications",
      });

      if (result.state === "granted") {
        return true;
      } else if (result.state === "prompt") {
        await showPrompt();
      } else {
        // TODO: denied
        return false;
      }
    }
  }

  const requestWebNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("Web notifications are not supported in this browser.");
      return "denied";
    }
    await checkWebNotificationPermission();
    const permission = await Notification.requestPermission();
    return permission;
  };

  return { notify };
}
